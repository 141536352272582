import React, { useState } from "react";
import { Modal, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setNetwork, setNetworkSwitchConfig } from "../../redux/actions/web3action";

function NetworkSwitchDialog(props) {
  const networkSwitchConfig = useSelector((state) => state.web3.networkSwitchConfig);
  const dispatch = useDispatch();
  const wcProvider = useSelector((state) => state.web3.wcProvider);
  const wcPopupConfig = useSelector((state) => state.web3.wcPopupConfig);

  const switchNetworkCalled = async () => {
      await switchToCustomNetwork(window.ethereum, networkSwitchConfig.newNetwork);
  }
 
  async function switchToCustomNetwork(ethereum, newNetwork){
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: newNetwork.chainId }],
      });
      dispatch(setNetwork(newNetwork))      
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902 || switchError.message.includes('Unrecognized chain ID')) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              { 
                chainName: newNetwork.chainName,
                chainId: newNetwork.chainId, 
                rpcUrls: [newNetwork.rpcUrl],
                nativeCurrency: newNetwork.nativeCurrency
              }
          ],
          });
          dispatch(setNetwork(newNetwork))          
        } catch (addError) {
          // handle "add" error
          console.log(addError);
        }
      }
      // handle other "switch" errors
    }
    dispatch(setNetworkSwitchConfig({
      ...networkSwitchConfig,
      show: false    
    }));    
  } 

  return (
    <Modal show={networkSwitchConfig.show} onHide={""}>
        <Modal.Header>
          <Modal.Title>In order to submit transactions, please switch to {networkSwitchConfig.newNetwork?.chainName} network within your wallet.</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="danger" onClick={() => {
                dispatch(setNetworkSwitchConfig({
                  ...networkSwitchConfig,
                  show: false, 
                  currentNetwork: null,
                  newNetwork: null
                }));
              }}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => {switchNetworkCalled()}}>
              Switch Network
          </Button>
        </Modal.Footer>
      </Modal>
  );
}
export default React.memo(NetworkSwitchDialog);
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import NotificationAlert from "react-notification-alert";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import httpService from "../../services/http.service";
import { useDispatch, useSelector } from "react-redux";
import { setChatJWT, setMainJWT } from "../../redux/actions/jwtAction";
import storageService from "../../services/storage.service";
const tokenKey = process.env.REACT_APP_USER_TOKEN_KEY;
import {
  web3Connected,
  setMetaMask,
  setWalletType,
  setSigner,
  setProvider
} from "../../redux/actions/web3action";


function Login() {
  let history = useHistory();
  
  const dispatch = useDispatch();
  const isWeb3Connected = useSelector((state) => state.web3.web3connected);
  const notificationAlertRef = React.useRef(null);

  const signIn = async (account, signer) => {
    const data = (
      await httpService.post("auth/nounce", {
        userAddress: account,
      })
    ).data;
    const signature = await signer.signMessage(
      `I am signing my one-time nonce${data[0].user_nounce}`
    );

    const login =  await httpService.post("/auth/signin", {
      userAddress: account,
      signature,
    });
    return login.data;
  };

  const connectWallet = async () => {
    const providerOptions = {
      /* See Provider Options Section */
    };

    const web3Modal = new Web3Modal({
      network: "mumbai", // optional
      cacheProvider: true, // optional
      providerOptions, // required
    });

    const instance = await web3Modal.connect();

    const provider = new ethers.providers.Web3Provider(instance);
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();

    const account = await signer.getAddress();

    const accessToken = storageService.getItem(tokenKey);
    if (!accessToken) {
      const data = await signIn(account, signer);
      storageService.setItem(tokenKey, data.access_token);
      const advertiser = (await httpService.get("/advertiser/by-address")).data;
      console.log('advertiser', advertiser)
      if (advertiser) {
        dispatch(setMetaMask(account));
        dispatch(web3Connected(true));
        dispatch(setChatJWT(data.access_token));
        dispatch(setWalletType("META_MASK"));
        dispatch(setSigner(signer));  
        dispatch(setProvider(provider));
      } else {
        localStorage.removeItem(tokenKey);
        notify('You are not an admin.', 'br', 'danger')
      }
    } else {
      dispatch(setMetaMask(account));
      dispatch(web3Connected(true));
      dispatch(setChatJWT(accessToken));
      dispatch(setWalletType("META_MASK"));
      dispatch(setSigner(signer));  
      dispatch(setProvider(provider));
    }
  };




  useEffect(() => {
    if(isWeb3Connected){
     history.push('/admin/dashboard');
    }
  },[isWeb3Connected]);

  useEffect(() => {
    if (storageService.getItem(tokenKey)) {
      connectWallet()
    }
  }, []);
  
  const notify = (message='TEST', place='br', variant='primary') => {
    let options = {
      place: place,
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: variant,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
 
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="wrapper d-flex align-items-center justify-content-center">
          <Button
            className="btn-fill pull-right"
            type="submit"
            variant="info"
            onClick={connectWallet}
          >
            Login using Metamask
          </Button>          
      </div>
    </>
  );
}

export default Login;
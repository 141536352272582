import {
  Web_3_Object,
  WEB_3_CONNECTED,
  SET_META_MASK_ADDRESS,
  DELETE_META_MASK_ADDRESS,
  SET_NETWORK,
  NETWORK_SWITCH_CONFIG,
  SET_WALLET_TYPE,
  SET_SIGNER,
  SET_PROVIDER
} from "../constants";

export const setMetaMask = (content) => ({
  type: SET_META_MASK_ADDRESS,
  payload: content,
});

export const setNetwork = (content) => ({
  type: SET_NETWORK,
  payload: content,
});

export const deleteMetaMask = () => ({ type: DELETE_META_MASK_ADDRESS });

export function Web3Object(value) {
  console.log('Web3Object', value);
  return {
    type: Web_3_Object,
    payload: value,
  };
}

export function web3Connected(value) {
  return {
    type: WEB_3_CONNECTED,
    payload: value,
  };
}

export function setNetworkSwitchConfig(value) {
  return {
    type: NETWORK_SWITCH_CONFIG,
    payload: value,
  };
}

export function setWalletType(value) {
  return {
    type: SET_WALLET_TYPE,
    payload: value,
  };
}

export function setSigner(value) {
  return {
    type: SET_SIGNER,
    payload: value,
  };
}

export function setProvider(value) {
  return {
    type: SET_PROVIDER,
    payload: value,
  };
}


// export default {Web3Object, web3Connected};

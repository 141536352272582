import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  Button,
  Card,
  Form,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import httpService from "../../services/http.service";

function Users(props) {
  
  const [users, setUsers] = useState([]);

  const getUsers = async () => {

    const _users =  await httpService.get("/user/all", {});
    if (_users.data) {
      setUsers(_users.data);
    }
  }

  useEffect(() => {
    getUsers();
  }, [])
  
  return (
    <>
      <Container fluid>
        <Row>          
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Card.Title as="h4">Users</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover">
                  <thead>
                    <tr>
                      <th className="border-0">Id</th>                      
                      <th className="border-0">Email</th>
                      <th className="border-0">Address</th>                      
                      <th className="border-0">Display Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map(user => (
                      <tr key={user.user_id}>
                        <td>{user.user_id}</td>
                        <td>{user.email}</td>
                        <td>{user.user_address}</td>                      
                        <td>@{user.display_name}</td>                        
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Users;

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  Button,
  Card,
  Form,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import httpService from "../../services/http.service";
import { useSelector } from "react-redux";
import { getNameOfJSDocTypedef } from "typescript";
import NotificationAlert from "react-notification-alert";

function Tags(props) {
  const notificationAlertRef = React.useRef(null);
  const [tags, setTags] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const metaMaskAddress = useSelector((state) => state.web3.metaMaskAddress);

  const getTags = async () => {
    const _tags =  await httpService.get("/tag/top-tags?take=200&skip=0");
    if (_tags.data) {
      setTags(_tags.data);
    }
  }

  useEffect(() => {
    getTags();
  }, [])

  const deleteTag = async () => {
    console.log('deleteTag', selectedTag);

    // if (selectedUser.advertiser_address.toLowerCase() == metaMaskAddress.toLowerCase()) {
    //   notify("You can't delete own user.", "br", "danger");
    //   return;
    // }

    const _tag = await httpService.remove(`/tag?id=${selectedTag.tag_id}`);
    setShowDeleteModal(false);
    if (_tag.data) {
      getTags();
    }
  }

  const hidedeleteModal = () => {
    setSelectedTag(null);
    setShowDeleteModal(false);
  };

  const notify = (message = "TEST", place = "br", variant = "primary") => {
    let options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: variant,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  
  return (
    <>
      <Container fluid>
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>          
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Row>
                  <Col md="10">
                  <Card.Title as="h4">Item Tags</Card.Title>
                  </Col>
                  <Col md="2">
                    
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover">
                  <thead>
                    <tr>
                      <th className="border-0">Id</th>                      
                      <th className="border-0">Tag Name</th>
                      {/* <th className="border-0">Item Count</th> */}
                      <th className="border-0">Like Count</th>                      
                      <th className="border-0">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tags.map(tag => (
                      <tr key={tag.tag_id}>
                        <td>{tag.tag_id}</td>
                        <td>{tag.name}</td>
                        {/* <td>{tag.items_count}</td> */}
                        <td>{tag.likes_count}</td>                      
                        <td>
                          <Button variant="info"
                            style={{ width: "100px"}}
                            onClick={() => {
                              setSelectedTag(tag);
                              setShowDeleteModal(true);
                            }}
                          >
                            Delete
                          </Button>
                        </td>                                
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal show={showDeleteModal} onHide={hidedeleteModal}>
        <Modal.Header>
          <Modal.Title>Action confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Items under this tag will lose this tag.
          Are you sure to delete the tag?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={hidedeleteModal}>
            No
          </Button>
          <Button variant="primary" onClick={()=>{
            deleteTag();
          }}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>     
    </>
  );
}

export default Tags;

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  Button,
  Card,
  Form,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import httpService from "../../services/http.service";

function Profiles(props) {
  
  const [profiles, setProfiles] = useState([]);

  const getProfiles = async () => {
    const _profiles =  await httpService.get("/profile/all", {});
    if (_profiles.data) {
      setProfiles(_profiles.data);
    }
  }

  useEffect(() => {
    getProfiles();
  }, [])
  
  return (
    <>
      <Container fluid>
        <Row>          
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Card.Title as="h4">Profiles</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover">
                  <thead>
                    <tr>
                      <th className="border-0">Id</th>                      
                      <th className="border-0">Image</th>
                      <th className="border-0">Name</th>
                      <th className="border-0">Owner</th>                      
                      <th className="border-0">Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {profiles.map(profile => (
                      <tr key={profile.profile_id}>
                        <td>{profile.profile_id}</td>
                        <td>
                          <img
                              alt="user_image"
                              className="avatar"
                              src={profile.image_url}
                            />
                        </td>
                        <td>{profile.name}</td>
                        <td>{profile.profile_owner}</td>                      
                        <td>{profile.created_at}</td>                        
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Profiles;

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  Button,
  Card,
  Form,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import httpService from "../../services/http.service";

function Promotions(props) {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [newPromotion, setNewPromotion] = useState({
    count: 10,
    expiryDuration: 100,
    length: 6,
    numberofcode: 1
});

  const [promotions, setPromotions] = useState([]);

  const getPromitions = async () => {

    const _promotions =  await httpService.post("/promotion/all", {});
    if (_promotions.data) {
      setPromotions(_promotions.data);
    }
  }

  const hideAddModal = () => {
    setNewPromotion({
      count: 10,
      expiryDuration: 10,
      length: 6,
      numberofcode: 1
    });
    setAddModalOpen(false);
  };
  useEffect(() => {
    getPromitions();
  }, [])


  const createPromotion = async () => {
    const promotions =  await httpService.post("/promotion/generate-promotion", newPromotion);
    console.log('promotions', promotions);
    getPromitions();

    setAddModalOpen(false);
  }
  
  return (
    <>
      <Container fluid>
        <Row>          
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                

                <Row>
                  <Col md="10">
                  <Card.Title as="h4">Promotions</Card.Title>
                  </Col>
                  <Col md="2">
                    <Button
                      variant="primary"
                      onClick={() => {
                        setAddModalOpen(true);
                      }}
                    >
                      Create Promotion
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover">
                  <thead>
                    <tr>
                      <th className="border-0">Id</th>                      
                      <th className="border-0">Author</th>
                      <th className="border-0">Used By</th>
                      <th className="border-0">Code</th>
                      <th className="border-0">Exppiry</th>
                      <th className="border-0">Created At</th>
                    </tr>
                  </thead>
                  <tbody>

                    
                    
                    {promotions.map(promotion => (
                      <tr key={promotion.promotion_id}>
                        <td>{promotion.promotion_id}</td>
                        <td>{promotion.promotion_author_address}</td>
                        <td>{promotion.promotion_logs.length ? `${promotion.promotion_logs[0].user_address}` : ''}</td>   
                        <td>{promotion.promotion_code}</td>  
                        <td>{promotion.promotion_expiry}</td>                        
                        <td>{promotion.created_at}</td>                        
                      </tr>
                    ))}
                    
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal show={addModalOpen} onHide={hideAddModal}>
        <Modal.Header>
          <Modal.Title>Add Admin Collection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <label>Exppiry Duration (days)</label>
            <Form.Control
              placeholder="100"
              type="number"
              value={newPromotion.expiryDuration}
              onChange={(e) => {
                setNewPromotion({
                  ...newPromotion,
                  expiryDuration: e.target.value
                });
              }}
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <label>Length</label>
            <Form.Control
              placeholder="6"
              type="number"
              value={newPromotion.length}
              onChange={(e) => {
                setNewPromotion({
                  ...newPromotion,
                  length: parseInt(e.target.value)
                });
              }}
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <label>Number of promo codes</label>
            <Form.Control
              placeholder="6"
              type="number"
              value={newPromotion.numberofcode}
              onChange={(e) => {
                setNewPromotion({
                  ...newPromotion,
                  numberofcode: parseInt(e.target.value)
                });
              }}
            ></Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={hideAddModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={()=>{
            createPromotion()
          }}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Promotions;

import React, { useEffect, useState } from "react";

// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import NotificationAlert from "react-notification-alert";
import Spinner from "react-bootstrap/Spinner";
import collection from "../../abis/KingdomNFTCollectionV1.json";
import { ethers } from "ethers";
import { useSelector } from "react-redux";
import httpService from "../../services/http.service";
import Modal from "react-bootstrap/Modal";

function ContractSettings(props) {
  const notificationAlertRef = React.useRef(null);
  const provider = useSelector((state) => state.web3.provider);
  const signer = useSelector((state) => state.web3.signer);
  const metaMaskAddress = useSelector((state) => state.web3.metaMaskAddress);

  const [mintFee, setMintFee] = useState(0);
  const [initialFee, setInitialFee] = useState(0);
  const [isFeeEdit, setFeeEdit] = useState(false);
  const [contract, setContract] = useState(null);
  const [mintFeeUsd, setMintFeeUsd] = useState(0);
  const [ethRate, setEthRate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isPublic, setPublic] = useState(false);
  const [contractBalance, setContractBalance] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [tracsactionType, setTransactionType] = useState('');
  

  async function getMintingFee() {
    if (!contract) {
      return;
    }
    const [_mintFee, _isPublic, _contractBalance] = await Promise.all([
      contract.mintFee(),
      contract.isPublic(),
      provider.getBalance(process.env.REACT_APP_COLLECTION_ONE)
    ]);

    // console.log("_contractBalance", _contractBalance);
    // const amount = await provider.getBalance(userAddress);
    setContractBalance(parseFloat(ethers.utils.formatEther(_contractBalance)).toFixed(4));
    // console.log("isPublic", _isPublic);

    const fee = parseFloat(ethers.utils.formatEther(_mintFee));
    setMintFee(fee);
    setInitialFee(fee);
    // console.log("mintFee", fee);
    setPublic(_isPublic);

    const rate = (
      await httpService.http.get(
        `https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD`
      )
    ).data["USD"];
    // console.log("rate", rate);
    setEthRate(parseFloat(rate));
    setMintFeeUsd(fee * parseFloat(rate));
  }

  const updateFee = async () => {


    if (process.env.REACT_APP_SUPER_ADMIN.toLowerCase() !== metaMaskAddress.toLowerCase()) {
      notify("Only super admin can do this transaction.", "br", "danger");
      return;
    }
    try {
      if (initialFee == mintFee) {
        notify("Fee not changed", "br", "danger");
      } else {
        setTransactionType('fee');
        setIsLoading(true);
        console.log(ethers.utils.parseEther(mintFee).toString());

        const tx = await contract
          .connect(signer)
          .setMintFee(ethers.utils.parseEther(mintFee).toString());
        await tx.wait();
        // console.log('tx', tx);
        setIsLoading(false);
        if (tx) {
          setFeeEdit(!isFeeEdit);
          setInitialFee(mintFee);
        }
      }
    } catch (exception) {
      setIsLoading(false);
      notify(exception.message, "br", "danger");
    }
  };

  const submitTransaction = () => {
    if (process.env.REACT_APP_SUPER_ADMIN.toLowerCase() !== metaMaskAddress.toLowerCase()) {
      notify("Only super admin can do this transaction.", "br", "danger");
      return;
    }
    switch (tracsactionType) {
      case 'public':
        updatePublic();
        break;
      case 'withdraw':
        withdrawBalance();
        break;
      default:
        break;
    }
  };

  const updatePublic = async () => {
    try {
      hideConfirmModal();
      setIsLoading(true);
      const tx = await contract.connect(signer).setIsPublic(!isPublic);
      await tx.wait();
      console.log("tx", tx);
      setIsLoading(false);
      if (tx) {
        setPublic(!isPublic);
      }
    } catch (exception) {
      setIsLoading(false);
      notify(exception.message, "br", "danger");
    }
  };

  const withdrawBalance = async () => {
    try {
      hideConfirmModal();
      setIsLoading(true);
      const tx = await contract.connect(signer).withdrawFees();
      await tx.wait();
      console.log("tx", tx);
      setIsLoading(false);
      if (tx) {
        const _contractBalance = await provider.getBalance(process.env.REACT_APP_COLLECTION_ONE)
        setContractBalance(parseFloat(ethers.utils.formatEther(_contractBalance)).toFixed(4));
      }
    } catch (exception) {
      setIsLoading(false);
      notify(exception.message, "br", "danger");
    }
  };

  const hideConfirmModal = () => {
    setShowConfirmModal(false);
  };

  useEffect(() => {
    if (provider) {
      const _contract = new ethers.Contract(
        process.env.REACT_APP_COLLECTION_ONE,
        collection.abi,
        provider
      );
      setContract(_contract);
    }
  }, [provider]);

  useEffect(() => {
    if (contract) {
      getMintingFee();
    }
  }, [contract]);

  const clickEdit = (e) => {
    if (isLoading) return;

    if (isFeeEdit) {
      setMintFee(initialFee);
    }
    setFeeEdit(!isFeeEdit);
  };

  const notify = (message = "TEST", place = "br", variant = "primary") => {
    let options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: variant,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  return (
    <>
      <Container fluid>
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Contract Settings</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col className="pr-1" md="12">
                      <Form.Group>
                        <label>Mint Fee ({mintFeeUsd.toFixed(3)} USD)</label>

                        <Row>
                          <Col md="8">
                            <Form.Control
                              disabled={!isFeeEdit}
                              placeholder="Id"
                              type="number"
                              value={mintFee}
                              onChange={(e) => {
                                setMintFeeUsd(
                                  ethRate * parseFloat(e.target.value)
                                );
                                setMintFee(e.target.value);
                              }}
                            ></Form.Control>
                          </Col>
                          <Col md="4">
                            <Button
                              name="market-token"
                              variant="primary"
                              style={{ width: "180px" }}
                              onClick={clickEdit}
                            >
                              {isFeeEdit ? "Cancel" : "Edit"}
                            </Button>
                            {isFeeEdit && (
                              <Button
                                name="market-token"
                                variant="primary"
                                onClick={updateFee}
                                style={{ width: "120px", marginLeft: 10 }}
                              >
                                {isLoading && tracsactionType == 'fee' ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  "Submit"
                                )}
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="12">
                      <Form.Group>
                        <label>Is contract open for all</label>

                        <Row>
                          <Col md="8">
                            <Form.Control
                              disabled={true}
                              placeholder="Id"
                              type="text"
                              value={isPublic}
                            ></Form.Control>
                          </Col>
                          <Col md="4">
                            <Button
                              name="market-token"
                              variant="primary"
                              style={{ width: "180px" }}
                              onClick={() => {
                                if (isLoading) return;
                                setTransactionType('public');
                                setShowConfirmModal(true);
                              }}
                            >
                              {isLoading && tracsactionType == 'public' ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  isPublic ? "Make Private" : "Make Public"
                                )}                              
                            </Button>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="12">
                      <Form.Group>
                        <label>Earned Balance</label>

                        <Row>
                          <Col md="8">
                            <Form.Control
                              disabled={true}
                              placeholder="Id"
                              type="text"
                              value={`${contractBalance} ${process.env.REACT_APP_NATIVE_CURRENCY}`}
                            ></Form.Control>
                          </Col>
                          <Col md="4">
                            <Button
                              name="market-token"
                              variant="primary"
                              style={{ width: "180px" }}
                              onClick={() => {
                                if (isLoading) return;
                                setTransactionType('withdraw');
                                setShowConfirmModal(true);
                              }}
                            >
                              {isLoading && tracsactionType == 'withdraw' ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  "Withdraw Balance"
                                )}                              
                            </Button>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal show={showConfirmModal} onHide={hideConfirmModal}>
        <Modal.Header>
          <Modal.Title>Activity confirmation!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to make this transaction?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={hideConfirmModal}>
            No
          </Button>
          <Button variant="danger" onClick={submitTransaction}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ContractSettings;

/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import { useWeb3React } from "@web3-react/core"
import routes from "routes.js";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../redux/actions/authAction";
import { setChatJWT, setMainJWT } from "../../redux/actions/jwtAction";
import {
  web3Connected,
  setMetaMask,
  Web3Object,
} from "../../redux/actions/web3action";
import NetworkSwitchDialog from "./networkSwitchDialog";

function Header() {
  const location = useLocation();
  const { deactivate } = useWeb3React()
  const isWeb3Connected = useSelector((state) => state.web3.web3connected);
  const metaMaskAddress = useSelector((state) => state.web3.metaMaskAddress);
  const networkSwitchConfig = useSelector((state) => state.web3.networkSwitchConfig);
  const dispatch = useDispatch();
  let history = useHistory();

  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  const disconnectWallet = async () => {
    deactivate();
    
    localStorage.removeItem(process.env.REACT_APP_USER_TOKEN_KEY);
    dispatch(web3Connected(false));
    dispatch(Web3Object(null));
    dispatch(setUserData({}));
    dispatch(setMetaMask(""));
  
  };

  useEffect(() => {
    if(!isWeb3Connected){
     history.push('/');
    }
  },[isWeb3Connected]);

  useEffect(() => {
    console.log('networkSwitchConfig', networkSwitchConfig);
  }, [networkSwitchConfig])

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}
          >
            <i className="fas fa-ellipsis-v"></i>
          </Button>
          <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
          >
            {getBrandText()}
          </Navbar.Brand>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav mr-auto" navbar>            
          </Nav>
          <Nav className="ml-auto" navbar>
            <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-icon">{metaMaskAddress}</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={(e) =>{
                  e.preventDefault();
                  disconnectWallet();
                }}
              >
                <span className="no-icon">Log out</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
      <NetworkSwitchDialog/>
    </Navbar>
  );
}

export default Header;

import React, {useEffect, useState} from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from "react-bootstrap";
// import { UserService } from "../services/user.service";
// import { useSelector } from "react-redux";


function User(props) {
  const network = useSelector((state) => state.web3.network);
  const userService = new UserService(network.backendUrl);
  const [currentUser, setCurrentUser] = useState(null);


  const getUser = async (address) => {
    const _user = await userService.getUser(address);
    console.log('_user', _user);
    setCurrentUser(_user);
  }

  useEffect(() => {
    console.log('address',props.match.params.address);
    getUser(props.match.params.address);
  }, [])

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">User Profile</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col className="pr-1" md="12">
                      <Form.Group>
                        <label>Id</label>
                        <Form.Control                          
                          disabled
                          placeholder="Id"
                          type="text"
                          value={currentUser?.id}
                        ></Form.Control>
                      </Form.Group>
                    </Col>                    
                  </Row>
                  <Row>
                    <Col className="pr-1" md="12">
                      <Form.Group>
                        <label>Address</label>
                        <Form.Control                          
                          disabled
                          placeholder="address"
                          type="text"
                          value={currentUser?.address}
                        ></Form.Control>
                      </Form.Group>
                    </Col>                    
                  </Row>
                  <Row>
                    <Col className="pr-1" md="12">
                      <Form.Group>
                        <label>Instagram URL</label>
                        <Form.Control                          
                          disabled
                          placeholder="instagram"
                          type="text"
                          value={currentUser?.instagram_url}
                        ></Form.Control>
                      </Form.Group>
                    </Col>                    
                  </Row>

                  <Row>
                    <Col className="pr-1" md="12">
                      <Form.Group>
                        <label>Twitter URL</label>
                        <Form.Control                          
                          disabled
                          placeholder="twitter"
                          type="text"
                          value={currentUser?.twitter_url}
                        ></Form.Control>
                      </Form.Group>
                    </Col>                    
                  </Row>

                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>NFT created</label>
                        <Form.Control                          
                          disabled
                          placeholder="0"
                          type="text"
                          value={currentUser?.createdNFTCount}
                        ></Form.Control>
                      </Form.Group>
                    </Col>    
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>NFT owned</label>
                        <Form.Control                          
                          disabled
                          placeholder="0"
                          type="text"
                          value={currentUser?.ownedNFTCount}
                        ></Form.Control>
                      </Form.Group>
                    </Col>                    
                  </Row>

                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Total Bought</label>
                        <Form.Control                          
                          disabled
                          placeholder="0"
                          type="text"
                          value={`${currentUser?.totalBought} ${network.nativeCurrency?.symbol}`}
                        ></Form.Control>
                      </Form.Group>
                    </Col>    
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Total Sold</label>
                        <Form.Control                          
                          disabled
                          placeholder="0"
                          type="text"                          
                          value={`${currentUser?.totalSold} ${network.nativeCurrency?.symbol}`}
                        ></Form.Control>
                      </Form.Group>
                    </Col>                    
                  </Row>
                  
                  {/* <Button
                    className="btn-fill pull-right"
                    type="submit"
                    variant="info"
                  >
                    Update Profile
                  </Button> */}
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <div className="card-image">
                <img
                  alt="..."
                  src={require("assets/img/photo-1431578500526-4d9613015464.jpeg")}
                ></img>
              </div>
              <Card.Body>
                <div className="author">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar border-gray"
                      src={currentUser?.avatar_img}
                    ></img>
                    <h5 className="title">@{currentUser?.username}</h5>
                  </a>
                  <p className="description">likes: {currentUser?.likes?.length}</p>
                  <p className="description">following: {currentUser?.following?.length}</p>
                  <p className="description">follower: {currentUser?.follower?.length}</p>
                </div>
                <p className="description text-center">
                 {currentUser?.bio}
                </p>
              </Card.Body>
              <hr></hr>
              <div className="button-container mr-auto ml-auto">
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-facebook-square"></i>
                </Button>
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-twitter"></i>
                </Button>
                <Button
                  className="btn-simple btn-icon"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  variant="link"
                >
                  <i className="fab fa-google-plus-square"></i>
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default User;

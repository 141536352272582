import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  Button,
  Card,
  Form,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import httpService from "../../services/http.service";
import { useSelector } from "react-redux";
import { getNameOfJSDocTypedef } from "typescript";
import NotificationAlert from "react-notification-alert";

function AdminUsers(props) {
  const notificationAlertRef = React.useRef(null);
  const [users, setUsers] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [isCreate, setCreate] = useState(false);
  const metaMaskAddress = useSelector((state) => state.web3.metaMaskAddress);

  const getAdminUsers = async () => {

    const _users =  await httpService.get("/advertiser");
    if (_users.data) {
      setUsers(_users.data);
    }
  }

  useEffect(() => {
    getAdminUsers();
  }, [])

  const updateUser = async () => {
    console.log('updateUser');
    let _user = null; 
    if (isCreate) {
      _user =  await httpService.post(`/advertiser`, selectedUser);
    } else {
      _user =  await httpService.put(`/advertiser?id=${selectedUser.advertiser_id}`, selectedUser);
    }
    
    if (_user.data) {
      getAdminUsers();
    }
    setShowEditModal(false);
  }
  const deleteUser = async () => {
    console.log('deleteUser', selectedUser);

    if (selectedUser.advertiser_address.toLowerCase() == metaMaskAddress.toLowerCase()) {
      notify("You can't delete own user.", "br", "danger");
      return;
    }

    if (selectedUser.advertiser_address.toLowerCase() == process.env.REACT_APP_SUPER_ADMIN.toLowerCase()) {
      notify("Super admin can't be deleted.", "br", "danger");
      return;
    }

    const _user = await httpService.remove(`/advertiser?id=${selectedUser.advertiser_id}`);
    setShowDeleteModal(false);
    if (_user.data) {
      getAdminUsers();
    }
    // history.push('/admin/user-report-detail/'+ report.id);
  }
  const hideEditModal = () => {
    setSelectedUser({});
    setShowEditModal(false);
  };

  const hidedeleteModal = () => {
    setSelectedUser({});
    setShowDeleteModal(false);
  };

  const notify = (message = "TEST", place = "br", variant = "primary") => {
    let options = {
      place: place,
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: variant,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  
  return (
    <>
      <Container fluid>
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>          
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Row>
                  <Col md="10">
                  <Card.Title as="h4">Admin Users</Card.Title>
                  </Col>
                  <Col md="2">
                    <Button
                      variant="primary"
                      onClick={() => {
                        setCreate(true);
                        setSelectedUser({});
                        setShowEditModal(true);
                      }}
                    >
                      Add User
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover">
                  <thead>
                    <tr>
                      <th className="border-0">Id</th>                      
                      <th className="border-0">Email</th>
                      <th className="border-0">Address</th>                      
                      <th className="border-0">Display Name</th>
                      <th className="border-0">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map(user => (
                      <tr key={user.advertiser_id}>
                        <td>{user.advertiser_id}</td>
                        <td>{user.email}</td>
                        <td>{user.advertiser_address}</td>                      
                        <td>{`${user.display_name ? '@'+user.display_name : ''}` }</td>
                        <td>
                          <Button variant="info"
                            style={{ width: "100px" }}
                            onClick={() => {
                              setCreate(false);
                              setSelectedUser(user);
                              setShowEditModal(true);
                            }}
                          >
                            Edit
                          </Button>
                          <Button variant="info"
                            style={{ width: "100px",  marginLeft: "20px" }}
                            onClick={() => {
                              setSelectedUser(user);
                              setShowDeleteModal(true);
                            }}
                          >
                            Delete
                          </Button>
                        </td>                                
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal show={showDeleteModal} onHide={hidedeleteModal}>
        <Modal.Header>
          <Modal.Title>Action confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure to delete this user?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={hidedeleteModal}>
            No
          </Button>
          <Button variant="primary" onClick={()=>{
            deleteUser()
          }}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEditModal} onHide={hideEditModal}>
        <Modal.Header>
          <Modal.Title>User details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <label>Wallet Address</label>
            <Form.Control
              placeholder="Wallet Address"
              type="text"
              value={selectedUser?.advertiser_address}
              onChange={(e) => {
                setSelectedUser({
                  ...selectedUser,
                  advertiser_address: e.target.value
                });
              }}
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <label>Display Name</label>
            <Form.Control
              placeholder="Display Name"
              type="text"
              value={selectedUser?.display_name ? selectedUser?.display_name:''}
              onChange={(e) => {
                setSelectedUser({
                  ...selectedUser,
                  display_name: e.target.value
                });
              }}
            ></Form.Control>
          </Form.Group>   
          <Form.Group>
            <label>First Name</label>
            <Form.Control
              placeholder="First Name"
              type="text"
              value={selectedUser?.first_name ? selectedUser?.first_name : ''}
              onChange={(e) => {
                setSelectedUser({
                  ...selectedUser,
                  first_name: e.target.value
                });
              }}
            ></Form.Control>
          </Form.Group>   
          <Form.Group>
            <label>Last Name</label>
            <Form.Control
              placeholder="Last Name"
              type="text"
              value={selectedUser?.last_name ? selectedUser?.last_name : ''}
              onChange={(e) => {
                setSelectedUser({
                  ...selectedUser,
                  last_name: e.target.value
                });
              }}
            ></Form.Control>
          </Form.Group>   
          <Form.Group>
            <label>Email</label>
            <Form.Control
              placeholder="Email"
              type="text"
              value={selectedUser?.email ? selectedUser?.email : ''}
              onChange={(e) => {
                setSelectedUser({
                  ...selectedUser,
                  email: e.target.value
                });
              }}
            ></Form.Control>
          </Form.Group>   
          <Form.Group>
            <label>Description</label>
            <Form.Control
              placeholder="Description"
              type="text"
              value={selectedUser?.description ? selectedUser?.description : ''}
              onChange={(e) => {
                setSelectedUser({
                  ...selectedUser,
                  description: e.target.value
                });
              }}
            ></Form.Control>
          </Form.Group>          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={hideEditModal}>
            No
          </Button>
          <Button variant="primary" onClick={()=>{
            updateUser()
          }}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminUsers;


// "advertiser_address": "0xcE2C809d18c105E28d5a96129B03B9Cff648D59d",
//    "display_name": null,
//    "first_name": null,
//    "last_name": null,
//    "email": null,
//    "description": null,

// import WalletConnectProvider from "@walletconnect/web3-provider";

// import Web3Modal from "web3modal";

import {
  Web_3_Object,
  WEB_3_CONNECTED,
  SET_META_MASK_ADDRESS,
  DELETE_META_MASK_ADDRESS,
  SET_NETWORK,
  NETWORK_SWITCH_CONFIG,
  SET_WALLET_TYPE,
  SET_SIGNER,
  SET_PROVIDER
} from "../constants";

const initialState = {
  web3object: null,
  metaMaskAddress: "",
  web3connected: false,
  networkSwitchConfig: {
    show: false,
    currentNetwork: null,
    newNetwork: null
  },
  walletType: null,
  signer: null,
  provider: null
};

const web3Reducer = (state = initialState, action) => {
  switch (action.type) {
    case Web_3_Object:
      return {
        ...state,
        web3object: action.payload,
      };
    case WEB_3_CONNECTED:
      return {
        ...state,
        web3connected: action.payload,
      };

    case SET_META_MASK_ADDRESS:
      return {
        ...state,
        metaMaskAddress: action.payload,
      };

    case SET_NETWORK:
      return {
        ...state,
        network: action.payload,
      }; 

    case DELETE_META_MASK_ADDRESS:
      return {
        ...state,
        metaMaskAddress: "",
      };

    case NETWORK_SWITCH_CONFIG:
      return {
        ...state,
        networkSwitchConfig: action.payload,
      };

    case SET_WALLET_TYPE:
      return {
        ...state,
        walletType: action.payload,
      };

    case SET_SIGNER:
      return {
        ...state,
        signer: action.payload,
      };
      
    case SET_PROVIDER:
      return {
        ...state,
        provider: action.payload,
      };
            
    default:
      return state;
  }
};
export default web3Reducer;

/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";
import Promotions from "views/Promotions";
import ContractSettings from "views/ContractSettings";
import Subscribers from "views/Subscribers";
import Users from "views/Users";
import Items from "views/Items";
import Profiles from "views/Profiles";
import AdminUsers from "views/AdminUsers";
import Tags from "views/Tags";

const dashboardRoutes = [
  // {
  //   upgrade: true,
  //   path: "/upgrade",
  //   name: "Log out",
  //   icon: "nc-icon nc-button-power",
  //   component: Upgrade,
  //   layout: "/admin"
  // },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/users/user-profile/:address",
    name: "User Profile",
    icon: "nc-icon nc-circle-09",
    component: UserProfile,
    layout: "/admin",
    redirect: true
  },
  {
    path: "/promotions",
    name: "Promotions",
    icon: "nc-icon nc-notes",
    component: Promotions,
    layout: "/admin"
  },
  {
    path: "/profiles",
    name: "Profiles",
    icon: "nc-icon nc-notes",
    component: Profiles,
    layout: "/admin"
  },
  {
    path: "/items",
    name: "Items",
    icon: "nc-icon nc-notes",
    component: Items,
    layout: "/admin"
  },
  {
    path: "/tags",
    name: "Tags",
    icon: "nc-icon nc-notes",
    component: Tags,
    layout: "/admin"
  },
  {
    path: "/subscribers",
    name: "Subscribers",
    icon: "nc-icon nc-notes",
    component: Subscribers,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Users",
    icon: "nc-icon nc-notes",
    component: Users,
    layout: "/admin"
  },
  {
    path: "/admin-users",
    name: "Admin Users",
    icon: "nc-icon nc-notes",
    component: AdminUsers,
    layout: "/admin"
  },
  {
    path: "/contract-settings",
    name: "Contract Settings",
    icon: "nc-icon nc-notes",
    component: ContractSettings,
    layout: "/admin"
  },
];

export default dashboardRoutes;

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  Button,
  Card,
  Form,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import httpService from "../../services/http.service";

function Subscribers(props) {
  
  const [subscribers, setSubscribers] = useState([]);

  const getSubscribers = async () => {

    const _subscribers =  await httpService.get("/subscriber/all", {});
    if (_subscribers.data) {
      setSubscribers(_subscribers.data);
    }
  }

  useEffect(() => {
    getSubscribers();
  }, [])
  
  return (
    <>
      <Container fluid>
        <Row>          
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Card.Title as="h4">Subscribers</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover">
                  <thead>
                    <tr>
                      <th className="border-0">Id</th>                      
                      <th className="border-0">Email</th>
                      <th className="border-0">Type</th>                      
                      <th className="border-0">Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscribers.map(subscriber => (
                      <tr key={subscriber.subscriber_id}>
                        <td>{subscriber.subscriber_id}</td>
                        <td>{subscriber.subscriber_email}</td>
                        <td>{subscriber.subscriber_type}</td>                      
                        <td>{subscriber.created_at}</td>                        
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Subscribers;
